export default function dialog() {
  return {
    visible: false,
    loading: false,
  }
}

export function showDialog(dialog) {
  const _this = this
  for (const dialogName in _this.dialog) {
    _this.dialog[dialogName].visible = false
  }
  if (_this.dialog[dialog]) {
    _this.dialog[dialog].visible = true
  }
}
