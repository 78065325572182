<template>
  <div>
    <el-form :inline="true" :model="model" :rules="form.rules">
      <el-form-item prop="companyKey">
        <select-company-by-service
          v-model="model.companyKey"
          :label-all="false"
          :service-id="serviceId"
          @set-selected="onChangeCompany"
        ></select-company-by-service>
      </el-form-item>
      <el-form-item prop="planId">
        <el-select
          v-model="model.planId"
          :placeholder="`${$tc('plan')}`"
          value-key="id"
          clearable
          @change="onBillPlan($event, true)"
        >
          <el-option
            v-for="item in plansByService"
            :key="item.id"
            :value="item.id"
            :label="item.planName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="voucherDetailPlanPrice">
        <el-input
          v-model="model.voucherDetailPlanPrice"
          :placeholder="$tc('price')"
          class="input-currency"
          @change="onChangePlanPrice"
        >
          <i slot="prefix" class="el-input__icon el-icon-money"></i>
        </el-input>
      </el-form-item>
      <el-form-item prop="voucherDetailType">
        <select-voucher-detail-type
          v-model="model.voucherDetailType"
          :label-all="false"
        ></select-voucher-detail-type>
      </el-form-item>
      <el-form-item>
        <el-badge :value="voucherDetailPlanFeatures.length">
          <el-button
            size="mini"
            icon="fad fa-cube"
            plain
            @click="onClickBillFeatures"
          >
            {{ $tc('feature') }}
          </el-button>
        </el-badge>
      </el-form-item>
      <el-form-item>
        <el-badge :value="(model.addOns || []).length">
          <el-button
            size="mini"
            icon="fad fa-puzzle-piece"
            plain
            @click="onClickBillAddOns"
          >
            {{ $tc('addOn') }}
          </el-button>
        </el-badge>
      </el-form-item>
      <!-- <el-form-item>
        <el-badge
          :hidden="voucherDetailPeriod"
          is-dot
        >
          <el-button size="mini"
            @click="onClickBillPeriod"
            plain
            icon="fad fa-calendar-day"
          ></el-button>
        </el-badge>
      </el-form-item> -->
      <el-form-item v-if="!(index === 0)">
        <el-tooltip :content="$tc('delete')" placement="top">
          <el-button
            size="mini"
            type="danger"
            plain
            icon="fad fa-trash-alt"
            @click="$emit('remove', index, model)"
          ></el-button>
        </el-tooltip>
      </el-form-item>
    </el-form>
    <el-dialog
      :visible.sync="dialog.billAddOn.visible"
      width="80%"
      append-to-body
      top="9vh"
    >
      <bill-add-on
        v-if="dialog.billAddOn.visible"
        ref="billAddOn"
        :data="list.addOns || []"
        :selected="model.addOns || []"
        @set-selected="onBillAddOns"
      ></bill-add-on>
    </el-dialog>
    <el-dialog
      :visible.sync="dialog.billFeature.visible"
      width="80%"
      append-to-body
      top="9vh"
      @closed="onBillPlanFeatures"
    >
      <bill-feature
        v-if="dialog.billFeature.visible"
        ref="billFeature"
        :company-service-frequency-billing="model.voucherDetailRecurrence || voucherRecurrence
      "
        :data.sync="model.features"
      ></bill-feature>
    </el-dialog>
    <el-dialog
      :visible.sync="dialog.billPeriod.visible"
      width="80%"
      append-to-body
      top="9vh"
    >
      <bill-period
        v-if="dialog.billPeriod.visible"
        :model.sync="model"
        @change-recurrence="onChangePlanRecurrence($event, true)"
      ></bill-period>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          type="primary"
          @click="dialog.billPeriod.visible = false"
          >Ok</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

/* vue
-------------------------------------------------- */

import Vue from 'vue'
import AsyncComputed from 'vue-async-computed'

import { mapState } from 'vuex'

/* End of vue
-------------------------------------------------- */

/* vendors
-------------------------------------------------- */

import { meanBy, sumBy } from 'lodash'

/* End of vendors
-------------------------------------------------- */

/* app
-------------------------------------------------- */

import dialog, { showDialog } from '@/views/common/dialog'
import { addOnTotalize } from '@/views/company/calculate-total'
import { calculateVoucherDetailPeriod } from './model'
import { voucherDetailRules } from './rules'

/* End of app
-------------------------------------------------- */

/* vue:config
-------------------------------------------------- */

Vue.use(AsyncComputed)

/* End of vue:config
-------------------------------------------------- */

export default {
  name: 'VoucherDetail',
  components: {
    billAddOn: _ => import('./bill-add-on'),
    billFeature: _ => import('./bill-feature'),
    billPeriod: _ => import('./bill-period')
  },
  props: {
    index: {
      required: true,
      default: 0,
      type: Number
    },
    model: {
      type: Object
    },
    serviceId: {
      required: true
    },
    voucherDate: {
      required: true
    },
    voucherRecurrence: {
      required: true
    }
  },
  data() {
    return {
      form: {
        rules: voucherDetailRules
      },
      dialog: {
        billAddOn: dialog(),
        billFeature: dialog(),
        billPeriod: dialog()
      },
      list: {
        addOns: []
      },
      loading: {
        addOn: false
      }
    }
  },
  methods: {
    showDialog,
    onChangeCompany(company) {
      const _this = this

      let newModel = {
        ..._this.model
      }

      if (company && company.id) {
        _this.list.addOns = company.addOns || []

        newModel = {
          ...newModel,
          ...{
            features: company.features || [],
            addOns: company.addOns || [],
            companyKey: company.companyKey,
            planId: company.planId,
            personId: company.commonPersonId,
            voucherDetailPlanPrice: company.companyServicePlanPrice

            // voucherDetailRecurrence: _this.voucherRecurrence || capitalize(company.companyServiceFrequencyBilling)
          }
        }
      } else {
        newModel = {
          ...newModel,
          ...{
            features: [],
            addOns: [],
            companyKey: '',
            planId: '',
            personId: '',
            voucherDetailPlanPrice: '',
            voucherDetailRecurrence: ''
          }
        }
      }

      _this.onBillPlan(newModel.planId, false, newModel)
    },
    async onBillPlan(id, updateService, newModel) {
      const _this = this

      const plan = _this.plansByService.find(item => item.id === id)

      if (!newModel) {
        newModel = { ..._this.model }
      }

      if (id && plan && plan.id) {
        newModel = {
          ...newModel,
          ...{
            planTaxPercentIncluded: +plan.planTaxPercentIncluded,
            planTaxPercentIncrease: +plan.planTaxPercentIncrease,
            voucherDetailPlanIvaValue: 0
          }
        }

        if (updateService) {
          newModel.voucherDetailPlanPrice =
            (
              _this.model.voucherDetailRecurrence || _this.voucherRecurrence
            ).toLowerCase() === 'yearly'
              ? plan.planPriceYearly
              : plan.planPriceMonthly

          newModel.voucherDetailPlanPrice =
            +newModel.planTaxPercentIncluded > 0
              ? +(newModel.voucherDetailPlanPrice /
                  (+newModel.planTaxPercentIncluded / 100 + 1),
              2).toFixed(2)
              : newModel.voucherDetailPlanPrice

          newModel.features = plan.features
        }

        newModel.voucherDetailPlanIvaPercent =
          +newModel.planTaxPercentIncluded || +newModel.planTaxPercentIncrease
        newModel.voucherDetailPlanTaxBase = newModel.voucherDetailPlanPrice
        newModel.voucherDetailPlanIvaValue =
          +newModel.planTaxPercentIncluded > 0
            ? newModel.voucherDetailPlanPrice
            : +(
              newModel.voucherDetailPlanPrice *
                (+newModel.planTaxPercentIncrease / 100)
            ).toFixed(2)
        newModel.voucherDetailPlanAmount = newModel.voucherDetailPlanPrice
        newModel.voucherDetailDescription = plan.planName

        _this.loading.addOn = true
        _this.$http
          .get(
            `add-ons/${_this.serviceId}/${plan.id}/findByServiceByPlan?onlyEnabled=true`
          )
          .then(result => (_this.list.addOns = result.body))
          .finally(_ => (_this.loading.addOn = false))
      } else {
        newModel = {
          ...newModel,
          ...{
            voucherDetailPlanPrice: 0,
            voucherDetailPlanTaxBase: 0,
            voucherDetailPlanIvaPercent: 0,
            voucherDetailPlanIvaValue: 0,
            voucherDetailPlanAmount: 0
          }
        }

        _this.loading.addOn = true
        _this.$http
          .get(`add-ons/${_this.serviceId}/findByService`)
          .then(result => (_this.list.addOns = result.body))
          .finally(_ => (_this.loading.addOn = false))
      }

      newModel = await _this.onBillPlanFeatures(newModel)
      await _this.onBillAddOns(newModel.addOns, newModel)
    },
    async onBillPlanFeatures(newModel) {
      const _this = this

      if (!newModel) {
        newModel = { ..._this.model }
      }

      newModel.voucherDetailPlanFeaturePrice =
        newModel.features && +newModel.features.length > 0
          ? newModel.features
            .filter(
              item => item.featureAddonQuantity === 'true' ||
                  +item.featureAddonQuantity > 0
            )
            .map(item => (item.featureAddonQuantity === 'true'
              ? +(+item.featureUnitPriceCustom).toFixed(2)
              : +(
                +item.featureAddonQuantity * +item.featureUnitPriceCustom
              ).toFixed(2))
            )
            .reduce((a, b) => a + b, 0)
          : 0
      newModel.voucherDetailPlanFeatureIvaPercent =
        +newModel.planTaxPercentIncluded || +newModel.planTaxPercentIncrease
      newModel.voucherDetailPlanFeatureTaxBase =
        +newModel.planTaxPercentIncluded > 0
          ? +(
            newModel.voucherDetailPlanFeaturePrice /
              (+newModel.planTaxPercentIncluded / 100 + 1)
          ).toFixed(2)
          : newModel.voucherDetailPlanFeaturePrice
      newModel.voucherDetailPlanFeatureIvaValue =
        +newModel.planTaxPercentIncluded > 0
          ? newModel.voucherDetailPlanFeaturePrice -
            newModel.voucherDetailPlanFeatureTaxBase
          : +(
            newModel.voucherDetailPlanFeaturePrice *
              (+newModel.planTaxPercentIncrease / 100)
          ).toFixed(2)
      newModel.voucherDetailPlanFeatureAmount =
        newModel.voucherDetailPlanFeaturePrice +
        newModel.voucherDetailPlanFeatureIvaValue

      _this.$emit('bill-feature', {
        ...newModel,
        ..._this.calculateTotals(newModel)
      })

      return newModel
    },
    async onBillAddOns(selected, newModel) {
      const _this = this

      if (!newModel) {
        newModel = { ..._this.model }
      }

      if (+selected.length) {
        const voucherDetailAddOnPrice = sumBy(selected, 'addOnPrice')
        selected = selected.map(item => addOnTotalize(item))
        newModel = {
          ...newModel,
          ...{
            addOns: selected,
            voucherDetailAddOnPrice,
            voucherDetailAddOnTaxBase: sumBy(selected, 'addOnTaxBase'),
            voucherDetailAddOnIvaPercent: meanBy(selected, 'addOnIvaPercent'),
            voucherDetailAddOnIvaValue: sumBy(selected, 'addOnIvaValue'),
            voucherDetailAddOnAmountTotal: sumBy(selected, 'addOnAmountTotal')
          }
        }

        _this.$emit('bill-add-on', {
          ...newModel,
          ..._this.calculateTotals(newModel)
        })

        return voucherDetailAddOnPrice
      }
      newModel = {
        ...newModel,
        ...{
          addOns: [],
          voucherDetailAddOnPrice: 0,
          voucherDetailAddOnTaxBase: 0,
          voucherDetailAddOnIvaPercent: 0,
          voucherDetailAddOnIvaValue: 0,
          voucherDetailAddOnAmountTotal: 0
        }
      }

      return Promise.resolve(
        _this.$emit('bill-add-on', {
          ...newModel,
          ..._this.calculateTotals(newModel)
        })
      )
    },
    onChangePlanPrice() {
      const _this = this

      const plan = _this.plansByService.find(
        item => item.id === _this.model.planId
      )

      let newModel = {
        ..._this.model
      }

      if (_this.model.voucherDetailPlanPrice && plan && plan.id) {
        newModel = {
          ...newModel,
          ...{
            planTaxPercentIncluded: +plan.planTaxPercentIncluded,
            planTaxPercentIncrease: +plan.planTaxPercentIncrease,
            voucherDetailPlanIvaValue: 0
          }
        }

        newModel.voucherDetailPlanIvaValue = +(
          newModel.voucherDetailPlanPrice *
          ((+newModel.planTaxPercentIncrease ||
            +newModel.planTaxPercentIncluded) /
            100)
        ).toFixed(2)

        newModel = {
          ...newModel,
          ...{
            voucherDetailPlanTaxBase:
              newModel.voucherDetailPlanPrice -
              +(
                newModel.voucherDetailPlanPrice *
                (+newModel.planTaxPercentIncluded / 100)
              ).toFixed(2),
            voucherDetailPlanIvaPercent:
              +newModel.planTaxPercentIncrease ||
              +newModel.planTaxPercentIncluded,
            voucherDetailPlanAmount: +newModel.planTaxPercentIncrease
              ? newModel.voucherDetailPlanPrice +
                newModel.voucherDetailPlanIvaValue
              : newModel.voucherDetailPlanPrice
          }
        }
      } else {
        newModel = {
          ...newModel,
          ...{
            voucherDetailPlanPrice: 0,
            voucherDetailPlanTaxBase: 0,
            voucherDetailPlanIvaPercent: 0,
            voucherDetailPlanIvaValue: 0,
            voucherDetailPlanAmount: 0
          }
        }
      }
      _this.$emit('bill-plan', {
        ...newModel,
        ..._this.calculateTotals(newModel)
      })
    },
    onChangePlanRecurrence(recurrence) {
      const _this = this

      const months = _this.getRecurrenceNumber(recurrence)

      const plan = _this.plansByService.find(
        item => item.id === _this.model.planId
      )

      let newModel = {
        ..._this.model,
        ...{
          voucherDetailRecurrence: recurrence
        },
        ...calculateVoucherDetailPeriod(_this.voucherDate, months)
      }

      if (plan && plan.id) {
        newModel = {
          ...newModel,
          ...{
            planTaxPercentIncluded: +plan.planTaxPercentIncluded,
            planTaxPercentIncrease: +plan.planTaxPercentIncrease,
            voucherDetailPlanIvaValue: 0
          }
        }

        newModel.voucherDetailPlanPrice =
          recurrence === 'Yearly' ? plan.planPriceYearly : plan.planPriceMonthly

        newModel.voucherDetailPlanIvaValue = +(
          newModel.voucherDetailPlanPrice *
          ((+newModel.planTaxPercentIncrease ||
            +newModel.planTaxPercentIncluded) /
            100)
        ).toFixed(2)

        newModel = {
          ...newModel,
          ...{
            voucherDetailPlanTaxBase:
              newModel.voucherDetailPlanPrice -
              +(
                newModel.voucherDetailPlanPrice *
                (+newModel.planTaxPercentIncluded / 100)
              ).toFixed(2),
            voucherDetailPlanIvaPercent:
              +newModel.planTaxPercentIncrease ||
              +newModel.planTaxPercentIncluded,
            voucherDetailPlanAmount: +newModel.planTaxPercentIncrease
              ? newModel.voucherDetailPlanPrice +
                newModel.voucherDetailPlanIvaValue
              : newModel.voucherDetailPlanPrice
          }
        }
      } else {
        newModel = {
          ...newModel,
          ...{
            voucherDetailPlanPrice: 0,
            voucherDetailPlanTaxBase: 0,
            voucherDetailPlanIvaPercent: 0,
            voucherDetailPlanIvaValue: 0,
            voucherDetailPlanAmount: 0
          }
        }
      }
      _this.$emit('bill-plan', {
        ...newModel,
        ..._this.calculateTotals(newModel)
      })
    },
    onClickBillAddOns() {
      const _this = this
      _this.loading.addOn = true
      let promise = Promise.resolve({})
      if (_this.model.planId) {
        promise = _this.$http.get(
          `add-ons/${_this.serviceId}/${_this.model.planId}/findByServiceByPlan?onlyEnabled=true`
        )
      } else {
        promise = _this.$http.get(`add-ons/${_this.serviceId}/findByService`)
      }
      promise
        .then(result => (_this.list.addOns = result.body))
        .finally(() => {
          this.showDialog('billAddOn')
          _this.loading.addOn = false
        })
    },
    onClickBillFeatures() {
      this.showDialog('billFeature')
    },
    onClickBillPeriod() {
      this.showDialog('billPeriod')
    },
    calculateTotals(newModel) {
      newModel.voucherDetailTaxBase =
        +newModel.voucherDetailPlanTaxBase +
        +newModel.voucherDetailPlanFeatureTaxBase +
        +newModel.voucherDetailAddOnTaxBase

      newModel.voucherDetailIvaBase = 0

      if (newModel.voucherDetailPlanIvaPercent > 0) {
        newModel.voucherDetailIvaBase += newModel.voucherDetailPlanTaxBase
      }

      if (newModel.voucherDetailPlanIvaPercent > 0) {
        newModel.voucherDetailIvaBase +=
          newModel.voucherDetailPlanFeatureTaxBase
      }

      if (newModel.voucherDetailAddOnIvaPercent > 0) {
        newModel.voucherDetailIvaBase += newModel.voucherDetailAddOnTaxBase
      }

      if (
        newModel.voucherDetailPlanIvaPercent &&
        newModel.voucherDetailAddOnIvaPercent
      ) {
        newModel.voucherDetailTaxPercent = +(
          (+newModel.voucherDetailPlanIvaPercent +
            +newModel.voucherDetailAddOnIvaPercent) /
          2
        ).toFixed(2)
      } else {
        newModel.voucherDetailTaxPercent =
          +newModel.voucherDetailPlanIvaPercent ||
          +newModel.voucherDetailAddOnIvaPercent
      }

      newModel.voucherDetailIvaValue =
        +newModel.voucherDetailPlanIvaValue +
        +newModel.voucherDetailPlanFeatureIvaValue +
        +newModel.voucherDetailAddOnIvaValue

      newModel.voucherDetailAmount =
        +newModel.voucherDetailPlanPrice +
        +newModel.voucherDetailPlanFeaturePrice +
        +newModel.voucherDetailAddOnPrice

      newModel.voucherDetailAmountTotal =
        +newModel.voucherDetailPlanAmount +
        +newModel.voucherDetailPlanFeatureAmount +
        (+newModel.voucherDetailAddOnAmountTotal || 0)

      return newModel
    },
    getRecurrenceNumber(recurrence) {
      let months = 0

      switch (recurrence) {
        case 'Yearly':
          months = 12
          break
        case 'Monthly':
          months = 1
          break
        case 'Quarterly':
          months = 3
          break
        case 'Semiannual':
          months = 6
          break
        case 'ThreeYearly':
          months = 36
          break
        case 'EighthYearly':
          months = 96
          break
        default:
          break
      }

      return months
    }
  },
  asyncComputed: {
    voucherDetailPlanFeatures: {
      get: _this => new Promise(resolve => {
        if (!_this.model.features || !_this.model.features.length) {
          Promise.resolve()
        }
        const features = _this.model.features.filter(
          item => +item.featureAddonQuantity ||
              item.featureAddonQuantity === 'true'
        )
        resolve(features)
      }),
      default: []
    },
    voucherDetailPeriod: {
      get: _this => Promise.resolve(
        !(
          _this.model.voucherDetailRecurrence ||
            _this.model.voucherDetailDateStart ||
            _this.model.voucherDetailDateEnd ||
            _this.model.voucherDetailDateDue
        )
      ),
      default: false
    }
  },
  computed: {
    ...mapState(['plansByService'])
  },
  watch: {
    voucherRecurrence(newValue) {}
  },
  created() {}
}
</script>
