import { meanBy, sumBy } from 'lodash'

export function companyPlan(price, plan = { }) {
  return {
    companyServicePlanPrice: price,
    get companyServicePlanTaxBase() {
      const _this = this
      if (plan && +plan.planTaxPercentIncluded) {
        return +(_this.companyServicePlanPrice / ((_this.companyServicePlanIvaPercent / 100) + 1)).toFixed(2)
      }

      return _this.companyServicePlanPrice
    },
    get companyServicePlanIvaBase() {
      return this.companyServicePlanTaxBase
    },
    get companyServicePlanIvaPercent() {
      return +(plan ? plan.planTaxPercentIncluded || plan.planTaxPercentIncrease || 0 : 0)
    },
    get companyServicePlanIvaValue() {
      const _this = this
      if (plan && +plan.planTaxPercentIncluded) {
        return +(+_this.companyServicePlanPrice - _this.companyServicePlanTaxBase).toFixed(2)
      }

      return +(+_this.companyServicePlanPrice * (_this.companyServicePlanIvaPercent / 100)).toFixed(2)
    },
    get companyServicePlanAmount() {
      const _this = this
      if (plan && +plan.planTaxPercentIncrease) {
        return +(+_this.companyServicePlanPrice + _this.companyServicePlanIvaValue).toFixed(2)
      }

      return +_this.companyServicePlanPrice
    },
  }
}

export function companyPlanFeatures(features = [], plan = { }) {
  return {
    get companyServiceFeaturePrice() {
      if (features && features.length > 0) {
        const value = sumBy(features, item => (+item.featureBilledValue > 0 ? +(item.featureBilledValue || 0) : 0))

        return isNaN(value) ? 0 : value
      }

      return 0
    },
    get companyServiceFeatureTaxBase() {
      const _this = this
      if (plan && +plan.planTaxPercentIncluded) {
        return +(_this.companyServiceFeaturePrice / ((_this.companyServiceFeatureIvaPercent / 100) + 1)).toFixed(2)
      }

      return _this.companyServiceFeaturePrice
    },
    get companyServiceFeatureIvaBase() {
      return this.companyServiceFeatureTaxBase
    },
    companyServiceFeatureIvaPercent: +(plan ? plan.planTaxPercentIncluded || plan.planTaxPercentIncrease || 0 : 0),
    get companyPlanFeatureIvaValue() {
      const _this = this
      if (plan && +plan.planTaxPercentIncluded) {
        return +(+_this.companyServiceFeaturePrice - _this.companyServiceFeatureTaxBase).toFixed(2)
      }

      return +(+_this.companyServiceFeaturePrice * (_this.companyServiceFeatureIvaPercent / 100)).toFixed(2)
    },
    get companyServiceFeatureAmount() {
      const _this = this
      if (plan && +plan.planTaxPercentIncrease) {
        return +(+_this.companyServiceFeaturePrice - _this.companyPlanFeatureIvaValue).toFixed(2)
      }

      return +_this.companyServiceFeaturePrice
    },
  }
}

export function addOns(addOns = []) {
  return {
    companyServiceAddOnPrice: +sumBy(addOns, 'addOnPrice'),
    get companyServiceAddOnTaxBase() {
      if (addOns && addOns.length > 0) {
        const value = sumBy(addOns, item => (+item.addOnTaxBase > 0 ? +(item.addOnTaxBase || 0) : 0))

        return isNaN(value) ? 0 : value
      }

      return 0
    },
    get companyServiceAddOnIvaBase() {
      return this.addOnTaxBase
    },
    get companyServiceAddOnIvaPercent() {
      if (addOns && addOns.length > 0) {
        const value = meanBy(addOns, item => (+item.addOnIvaPercent > 0 ? +(item.addOnIvaPercent || 0) : 0))

        return isNaN(value) ? 0 : value
      }

      return 0
    },
    get companyServiceAddOnIvaValue() {
      if (addOns && addOns.length > 0) {
        const value = sumBy(addOns, item => (+item.addOnIvaValue > 0 ? +(item.addOnIvaValue || 0) : 0))

        return isNaN(value) ? 0 : value
      }

      return 0
    },
    get companyServiceAddOnAmount() {
      if (addOns && addOns.length > 0) {
        const value = sumBy(addOns, item => (+item.addOnAmountTotal > 0 ? +(item.addOnAmountTotal || 0) : 0))

        return isNaN(value) ? 0 : value
      }

      return 0
    },
  }
}

export function total(companyPlan, companyPlanFeature, addOn) {
  return {
    companyServiceAmount: companyPlan.companyServicePlanPrice + companyPlanFeature.companyServiceFeaturePrice + addOn.companyServiceAddOnPrice,
    companyServiceTaxBase: companyPlan.companyServicePlanTaxBase + companyPlanFeature.companyServiceFeatureTaxBase + addOn.companyServiceAddOnTaxBase,
    companyServiceIvaBase: companyPlan.companyServicePlanIvaBase + companyPlanFeature.companyServiceFeatureIvaBase + addOn.companyServiceAddOnIvaBase,
    get companyServiceIvaPercent() {
      if (companyPlan.companyServiceIvaPercent > 0 && addOn.companyServiceAddOnIvaPercent > 0) {
        return +((companyPlan.companyServiceIvaPercent + addOn.companyServiceAddOnIvaPercent) / 2).toFixed(2)
      }

      return (companyPlan.companyServiceIvaPercent || 0) + (addOn.companyServiceAddOnIvaPercent || 0)
    },
    get companyServiceIvaValue() {
      return companyPlan.companyServicePlanIvaValue + companyPlanFeature.companyPlanFeatureIvaValue + addOn.companyServiceAddOnIvaValue
    },
    companyServiceAmountTotal: companyPlan.companyServicePlanAmount + companyPlanFeature.companyServiceFeatureAmount + addOn.companyServiceAddOnAmount,
  }
}

export function addOnTotalize(addOn) {
  const addOnIvaPercent = +addOn.addOnTaxPercentIncluded || +addOn.addOnTaxPercentIncrease || 0

  let addOnIvaValue = 0
  let addOnTaxBase = +addOn.addOnPrice

  if (+addOn.addOnTaxPercentIncluded) {
    addOnTaxBase = +(+addOn.addOnPrice / ((addOnIvaPercent / 100) + 1)).toFixed(2)
  }

  if (+addOn.addOnTaxPercentIncluded) {
    addOnIvaValue = +(+addOn.addOnPrice - addOnTaxBase).toFixed(2)
  } else {
    addOnIvaValue = +(+addOn.addOnPrice * (addOnIvaPercent / 100)).toFixed(2)
  }

  return {
    ...addOn,
    addOnTaxBase,
    addOnIvaPercent,
    addOnIvaValue,
    addOnAmountTotal: +addOn.addOnTaxPercentIncrease ? addOn.addOnPrice + addOnIvaValue : +addOn.addOnPrice,
  }
}
